import React, { useEffect, useState } from 'react'
import { useQuill } from 'react-quilljs'
import BlotFormatter from 'quill-blot-formatter'
import 'quill/dist/quill.snow.css'

interface Props {
  initialValue: string
  setTextValue: (newType: string) => void
  height?: string
}
const QuillEditor = ({ initialValue, setTextValue, height }: Props) => {
  const [loaded, setLoaded] = useState(false)
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {} },
  })

  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register('modules/blotFormatter', BlotFormatter)
  }

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setTextValue(quill.root.innerHTML)
      })
      if (!loaded && initialValue.length > 0) {
        quill.clipboard.dangerouslyPasteHTML(initialValue)
        setLoaded(true)
      }
    }
  }, [quill, Quill, initialValue])

  return (
    <div style={{ height }}>
      <div ref={quillRef} />
    </div>
  )
}

export default QuillEditor
