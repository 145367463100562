import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../store'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

import { AppBreadcrumb } from './index'
import { logo } from '../assets/brand/logo'
import AppHeaderDropdown from '../components/AdminHeader/AppHeaderDropdown'
import { useSession } from '../hooks'
import { cilMenu } from '@coreui/icons'

const AppHeader = (): JSX.Element => {
  const dispatch = useDispatch()
  const { user } = useSession()
  const sidebarShow = useTypedSelector((state) => state.sidebarShow)

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none">
          <CIcon icon={logo} height={48} />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/admin/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto me-4">
          <AppHeaderDropdown user={user} />
        </CHeaderNav>
        {/*<CHeaderToggler*/}
        {/*  className="px-md-0 me-md-3"*/}
        {/*  onClick={() => dispatch({ type: 'set', asideShow: !asideShow })}*/}
        {/*>*/}
        {/*  <CIcon icon={cilApplicationsSettings} size="lg" />*/}
        {/*</CHeaderToggler>*/}
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
