import React, { useEffect, useState } from 'react'
import { AdminLayout } from '../../../layout'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { NewIntakeModel, UserData } from '@/models/offenders.model'
import { getSubmissions } from '@/core/apis/api'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const [submissions, setSubmissions] = useState<NewIntakeModel[]>([])
  const navigate = useNavigate()
  const incidentType = (type: string) => {
    switch (type) {
      case 'online':
        return 'Online'

      case 'in-person':
        return 'In-Person'

      default:
        return 'Online and In-Person'
    }
  }

  const columns = [
    { key: 'offender_name' },
    {
      key: 'incident_location',
    },
    'incident_type',
    {
      key: 'show_details',
      label: 'Options',
      _style: { width: '1%' },
      filter: false,
      sorter: false,
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSubmissions()
      setSubmissions(response)
    }

    fetchData().then()
  }, [])

  return (
    <AdminLayout>
      <CRow>
        <CCol md={12}>
          <CCard>
            <CCardHeader>Submissions</CCardHeader>
            <CCardBody>
              <CSmartTable
                columns={columns}
                items={submissions}
                scopedColumns={{
                  incident_type: (item: UserData) => {
                    return <td>{incidentType(item.incident_type)}</td>
                  },
                  show_details: (item: UserData) => {
                    return (
                      <td className="py-2">
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            navigate('/admin/intake/' + item.id)
                          }}
                        >
                          View
                        </CButton>
                      </td>
                    )
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </AdminLayout>
  )
}

export default Dashboard
