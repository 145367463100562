import React, { Component } from 'react'
import './scss/style.scss'
import { Router } from './router'

class App extends Component {
  render() {
    return <Router />
  }
}

export default App
