import { Box, Text, Flex, Wrap, WrapItem, Image } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'quill/dist/quill.snow.css'
import { DefaultLayout } from '../../../layout'
import { getOffender } from '../../../core/apis/api'
import { UserData } from '../../../models/offenders.model'

export default function Offender() {
  const { id } = useParams()
  const [offender, setOffender] = useState<UserData>()

  const incidentType = (type: string) => {
    switch (type) {
      case 'online':
        return 'Online'

      case 'in-person':
        return 'In-Person'

      default:
        return 'Online and In-Person'
    }
  }

  useEffect(() => {
    if (id) {
      const fetchData = async (id: number) => {
        const response = await getOffender(id)
        setOffender(response)
      }

      fetchData(+id).then()
    }
  }, [id])

  return (
    <DefaultLayout>
      {offender && (
        <Box as="section" width={'full'}>
          <Flex
            maxW={{ base: '100%', md: '50%' }}
            justifyContent={{ base: 'start', md: 'center' }}
            justify={'center'}
          >
            <Flex
              px={{ base: 2 }}
              justifyContent={{ base: 'center' }}
              // width={'100vw'}
            >
              <Text
                textAlign="left"
                fontSize="4xl"
                fontWeight="bold"
                bg="whiteAlpha.900"
              >
                {offender.name}
              </Text>
            </Flex>
          </Flex>
          <Flex
            maxW={{ base: '100%', md: '70%' }}
            justifyContent={{ base: 'start', md: 'center' }}
            justify={'center'}
          >
            <Wrap spacing={{ base: 0, md: '30px' }} justify={{ base: 'start' }}>
              <WrapItem>
                <Text
                  maxW="4xl"
                  textAlign="left"
                  fontSize="md"
                  fontWeight="normal"
                  bg="whiteAlpha.900"
                  px={{ base: 3 }}
                >
                  Date of incident:
                  <Text textAlign="left" fontSize="md" fontWeight="bold">
                    {offender.incident_date}
                  </Text>
                </Text>
              </WrapItem>
              <WrapItem>
                <Text
                  maxW="4xl"
                  textAlign="left"
                  fontSize="md"
                  fontWeight="normal"
                  bg="whiteAlpha.900"
                  px={{ base: 3 }}
                >
                  Location of incident:
                  <Text textAlign="left" fontSize="md" fontWeight="bold">
                    {offender.incident_location}
                  </Text>
                </Text>
              </WrapItem>
              <WrapItem>
                <Text
                  maxW="4xl"
                  textAlign="left"
                  fontSize="md"
                  fontWeight="normal"
                  bg="whiteAlpha.900"
                  px={{ base: 3 }}
                >
                  Type of incident:
                  <Text textAlign="left" fontSize="md" fontWeight="bold">
                    {incidentType(offender.incident_type)}
                  </Text>
                </Text>
              </WrapItem>
            </Wrap>
          </Flex>
          <Flex justifyContent={'center'}>
            <Image
              boxSize={{ base: '100%', md: '30%' }}
              objectFit="cover"
              src={process.env.REACT_APP_API_URL + '/' + offender.picture_url}
              title={'offenderPic'}
            />
          </Flex>
          <div
            className={'ql-editor'}
            dangerouslySetInnerHTML={{ __html: offender.page_text }}
          />
        </Box>
      )}
    </DefaultLayout>
  )
}
