import React from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Center } from '@chakra-ui/react'

const Page404 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              alt=""
              width={500}
              height={250}
            />
          </Grid>
          <Grid item xs={12}>
            <Center>
              <Typography variant="h6">
                The page you’re looking for doesn’t exist.
              </Typography>
            </Center>
            <Center>
              <Link to={'/'}>
                <Button variant="contained">Back Home</Button>
              </Link>
            </Center>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Page404
