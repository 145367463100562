// Public Paths
const ROOT_PATH = '/'
const LOGIN_PATH = '/login'
const PUBLIC_OFFENDERS_PATH = '/offenders'
const PUBLIC_OFFENDER_PATH = '/offenders/:id'
const REGISTER_PATH = '/register'
const INTAKE_PATH = '/report-an-incident'
const THANK_YOU_PATH = '/thank-you'

// Private Paths
const ADMIN_PATH = '/admin'
const DASHBOARD_PATH = '/admin/dashboard'
const BLANK_PATH = '/admin/blank'
const ADMIN_OFFENDERS_PATH = '/admin/offenders'
const ADMIN_OFFENDER_NEW_PATH = '/admin/offenders/new'
const ADMIN_OFFENDER_ID_PATH = '/admin/offenders/:id'
const ADMIN_INTAKE_ID_PATH = '/admin/intake/:id'

const paths = {
  ROOT_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  INTAKE_PATH,
  THANK_YOU_PATH,
  PUBLIC_OFFENDERS_PATH,
  PUBLIC_OFFENDER_PATH,
  ADMIN_PATH,
  DASHBOARD_PATH,
  BLANK_PATH,
  ADMIN_OFFENDERS_PATH,
  ADMIN_OFFENDER_NEW_PATH,
  ADMIN_OFFENDER_ID_PATH,
  ADMIN_INTAKE_ID_PATH,
} as const

export default paths
