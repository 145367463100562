import React from 'react'
import { Box, Container, Stack, Text } from '@chakra-ui/react'
import CTA_Background from '@/assets/images/cta_background.png'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRoutePaths } from '@/hooks'

const Hero = () => {
  const navigate = useNavigate()
  const { INTAKE_PATH } = useRoutePaths()

  const navigateToIntake = () => {
    navigate(INTAKE_PATH)
  }

  return (
    <div>
      <Box
        as="section"
        backgroundImage={CTA_Background}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Text
                maxW="4xl"
                textAlign="left"
                fontSize="xl"
                fontWeight="bold"
                bg="whiteAlpha.900"
                px={{ base: '2', md: '0' }}
              >
                Stopping hate.{' '}
                <span style={{ whiteSpace: 'nowrap' }}>
                  Protecting Muslims.
                </span>{' '}
                <br />
                Spreading truth!
              </Text>
            </Stack>
            <Stack
              spacing="3"
              direction={{ base: 'column', sm: 'row' }}
              justify="center"
              px={{ base: '8', md: '0' }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => navigateToIntake()}
              >
                Tell us your story
              </Button>
              {/*<Button variant="contained" color="secondary">*/}
              {/*  More Info*/}
              {/*</Button>*/}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </div>
  )
}

export default Hero
