import React, { FormEvent, useState } from 'react'
import { DefaultLayout } from '@/layout'

const ThankYou = () => {
  return (
    <DefaultLayout>
      <h4 className="p-5 text-center">
        Thank you for helping stop Islamophobia!
      </h4>
    </DefaultLayout>
  )
}

export default ThankYou
