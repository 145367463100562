import { api } from '../../services'
import { AxiosError } from 'axios'
import {
  NewIntakeModel,
  NewOffenderModel,
  UpdateOffenderModel,
} from '@/models/offenders.model'

const app_url = `${process.env.REACT_APP_API_URL}/api`

export async function getOffenders() {
  try {
    const response = await api.get(`${app_url}/offenders`, {})

    return response.data
  } catch (error) {
    const err = error as AxiosError
    return err
  }
}

export async function getOffender(id: number) {
  try {
    const response = await api.get(`${app_url}/offenders/${id}`, {})

    return response.data
  } catch (error) {
    const err = error as AxiosError
    return err
  }
}
export async function createOffender(offenderData: NewOffenderModel) {
  try {
    const formData = new FormData()
    formData.append('name', offenderData.name)
    formData.append('incident_date', offenderData.incident_date)
    formData.append('incident_location', offenderData.incident_location)
    formData.append('incident_type', offenderData.incident_type)
    if (offenderData.picture_of_offender) {
      formData.append('picture_of_offender', offenderData.picture_of_offender)
    }
    formData.append('page_text', offenderData.page_text)

    const response = await api.post(`${app_url}/offenders`, formData)

    return response
  } catch (error) {
    const err = error as AxiosError
    return err
  }
}

export async function getSubmissions() {
  try {
    const response = await api.get(`${app_url}/intake`, {})

    return response.data
  } catch (error) {
    const err = error as AxiosError
    return err
  }
}

export async function getSubmission(id: number) {
  try {
    const response = await api.get(`${app_url}/intake/${id}`, {})

    return response.data
  } catch (error) {
    const err = error as AxiosError
    return err
  }
}

export async function saveIntake(values: NewIntakeModel) {
  try {
    const formData = new FormData()
    if (values.attachments) {
      for (let i = 0; i < values.attachments.length; ++i) {
        const file = values.attachments[i]
        formData.append('attachments[]', file)
      }
    }
    formData.append('full_name', values.full_name)
    formData.append('email', values.email)
    formData.append('phone_number', values.phone_number)
    formData.append('location', values.location)
    formData.append('incident_date', values.incident_date ?? '')
    formData.append('incident_type', values.incident_type)
    formData.append('incident_location', values.incident_location)
    formData.append('offender_name', values.offender_name)
    formData.append('offender_facebook', values.offender_facebook)
    formData.append('offender_linkedin', values.offender_linkedin)
    formData.append('offender_instagram', values.offender_instagram)
    formData.append('offender_twitter', values.offender_twitter)
    formData.append('offender_employer', values.offender_employer)
    formData.append('summary', values.summary)

    return await api.post(`${app_url}/intake`, formData)
  } catch (error) {
    const err = error as AxiosError
    return err
  }
}

export async function saveOffender(
  offenderData: UpdateOffenderModel,
  id: number,
) {
  try {
    const formData = new FormData()
    formData.append('name', offenderData.name)
    formData.append('incident_date', offenderData.incident_date)
    formData.append('incident_location', offenderData.incident_location)
    formData.append('incident_type', offenderData.incident_type)
    if (offenderData.picture_of_offender) {
      formData.append('picture_of_offender', offenderData.picture_of_offender)
    }
    formData.append('page_text', offenderData.page_text)
    formData.append('_method', 'PUT')

    const response = await api.post(`${app_url}/offenders/${id}`, formData)

    return response
  } catch (error) {
    const err = error as AxiosError
    return err
  }
}
