import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCardBody,
  CCol,
  CCollapse,
  CRow,
  CSmartTable,
  CSpinner,
} from '@coreui/react-pro'
import { Center } from '@chakra-ui/react'
import moment from 'moment'
import { UserData } from '../../../models/offenders.model'
import { useNavigate } from 'react-router-dom'
import { AdminLayout } from '../../../layout'
import { getOffenders } from '../../../core/apis/api'

const AdminOffenders = () => {
  const [usersData, setUsersData] = useState<UserData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const addNewOffender = (e: React.MouseEvent) => {
    e.preventDefault()
    return navigate(`/admin/offenders/new`)
  }

  const columns = [
    {
      key: 'name',
      _style: { width: '20%' },
    },
    'incident_date',
    {
      key: 'incident_location',
      _style: { width: '20%' },
    },
    {
      key: 'incident_type',
      _style: { width: '20%' },
    },
    {
      key: 'show_details',
      label: 'Options',
      _style: { width: '1%' },
      filter: false,
      sorter: false,
    },
  ]

  const incidentType = (type: string) => {
    switch (type) {
      case 'online':
        return 'Online'

      case 'in-person':
        return 'In-Person'

      default:
        return 'Online and In-Person'
    }
  }

  const incidentDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY')
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOffenders()
      setUsersData(response)
      setIsLoading(false)
    }

    fetchData().catch(console.error)
  }, [])

  return (
    <AdminLayout>
      {isLoading && (
        <Center>
          <CSpinner color="primary" />
        </Center>
      )}
      {!isLoading && (
        <>
          <CRow>
            <CCol xs={12}>
              <CButton className="float-end" onClick={addNewOffender}>
                Add New Offender
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={12}>
              <CSmartTable
                activePage={1}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                footer
                items={usersData}
                itemsPerPageSelect
                itemsPerPage={50}
                pagination
                scopedColumns={{
                  incident_date: (item: UserData) => {
                    return <td>{incidentDate(item.incident_date)}</td>
                  },
                  incident_type: (item: UserData) => {
                    return <td>{incidentType(item.incident_type)}</td>
                  },
                  show_details: (item: UserData) => {
                    return (
                      <td className="py-2">
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            navigate('/admin/offenders/' + item.id)
                          }}
                        >
                          View
                        </CButton>
                      </td>
                    )
                  },
                }}
                // selectable
                sorterValue={{ column: 'status', state: 'asc' }}
                tableFilter
                tableProps={{
                  className: 'add-this-class',
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: 'align-middle',
                }}
              />
            </CCol>
          </CRow>
        </>
      )}
    </AdminLayout>
  )
}

export default AdminOffenders
