import React, { MouseEvent, useEffect, useState } from 'react'
import { Box, Container, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { getOffenders } from '../core/apis/api'
import { UserData } from '@/models/offenders.model'

const OffendersCarousel = () => {
  const navigate = useNavigate()
  const navigateToOffender = (e: MouseEvent<HTMLDivElement>, id: number) => {
    e.preventDefault()
    return navigate(`/offenders/${id}`)
  }

  const [civilianOffenders, setCivilianOffenders] = useState<UserData[]>([])

  // const publicOffenders = [
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 1',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 2',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 3',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 4',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 5',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 6',
  //     type: 'Online incident',
  //   },
  // ]

  // const civilianOffenders = [
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 1',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 2',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 3',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 4',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 5',
  //     type: 'Online incident',
  //   },
  //   {
  //     image: DeanYakov,
  //     name: 'Dean Yakov 6',
  //     type: 'Online incident',
  //   },
  // ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    smallerTablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  const incidentType = (type: string) => {
    switch (type) {
      case 'online':
        return 'Online'

      case 'in-person':
        return 'In-Person'

      default:
        return 'Online and In-Person'
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOffenders()
      setCivilianOffenders(response)
    }

    fetchData().then()
  }, [])

  return (
    <>
      <Box as="section" width="full">
        <Container py={{ base: '8', md: '8' }} px={{ base: '5' }}>
          <Text
            maxW="4xl"
            textAlign="left"
            fontSize="xl"
            fontWeight="bold"
            bg="whiteAlpha.900"
          >
            Civilian Offenders ({civilianOffenders.length})
          </Text>
          <Carousel containerClass="carousel-container" responsive={responsive}>
            {civilianOffenders.map((offender, index) => {
              return (
                <Card
                  onClick={(e) => navigateToOffender(e, offender.id)}
                  sx={{ marginLeft: '5px', marginRight: '5px' }}
                  key={index}
                >
                  <CardMedia
                    sx={{ height: 300 }}
                    image={
                      process.env.REACT_APP_API_URL + '/' + offender.picture_url
                    }
                    title="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {offender.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {incidentType(offender.incident_type)}
                    </Typography>
                  </CardContent>
                </Card>
              )
            })}
          </Carousel>
        </Container>
      </Box>
      {/*<Box as="section" width="full">*/}
      {/*  <Container py={{ base: '8', md: '8' }} px={{ base: '5' }}>*/}
      {/*    <Text*/}
      {/*      maxW="4xl"*/}
      {/*      textAlign="left"*/}
      {/*      fontSize="xl"*/}
      {/*      fontWeight="bold"*/}
      {/*      bg="whiteAlpha.900"*/}
      {/*    >*/}
      {/*      Public Offenders ({publicOffenders.length})*/}
      {/*    </Text>*/}
      {/*    <Carousel containerClass="carousel-container" responsive={responsive}>*/}
      {/*      {publicOffenders.map((offender, index) => {*/}
      {/*        return (*/}
      {/*          <Card*/}
      {/*            onClick={(e) => navigateToOffender(e, index)}*/}
      {/*            sx={{ marginLeft: '5px', marginRight: '5px' }}*/}
      {/*            key={index}*/}
      {/*          >*/}
      {/*            <CardMedia*/}
      {/*              sx={{ height: 300 }}*/}
      {/*              image={offender.image}*/}
      {/*              title="green iguana"*/}
      {/*            />*/}
      {/*            <CardContent>*/}
      {/*              <Typography*/}
      {/*                gutterBottom*/}
      {/*                variant="h5"*/}
      {/*                component="div"*/}
      {/*                sx={{ fontWeight: 'bold' }}*/}
      {/*              >*/}
      {/*                {offender.name}*/}
      {/*              </Typography>*/}
      {/*              <Typography variant="body2" color="text.secondary">*/}
      {/*                {offender.type}*/}
      {/*              </Typography>*/}
      {/*            </CardContent>*/}
      {/*          </Card>*/}
      {/*        )*/}
      {/*      })}*/}
      {/*    </Carousel>*/}
      {/*  </Container>*/}
      {/*</Box>*/}
    </>
  )
}

export default OffendersCarousel
