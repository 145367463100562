import React, { useEffect, useReducer, useState } from 'react'
import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react-pro'
import QuillEditor from '../../../components/QuillEditor'
import './new-offender.scss'
import { AdminLayout } from '../../../layout'
import {
  NewOffenderModel,
  UpdateOffenderModel,
} from '../../../models/offenders.model'
import {
  createOffender,
  getOffender,
  saveOffender,
} from '../../../core/apis/api'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'

type Inputs = {
  name: string
  incident_date: string
  incident_location: string
  incident_type: string
  picture_of_offender: File
  page_text: string
}

const NewOffender = () => {
  const [pageText, setPageText] = useState<string>('')
  const [pageTextValid, setPageTextValid] = useState(true)
  const [validated, setValidated] = useState(false)
  const [data, setData] = useState<UpdateOffenderModel>()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const navigate = useNavigate()
  const params = useParams()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (inputs) => {
    setPageTextValid(false)
    if (pageText !== '<p><br></p>' && pageText !== '') {
      setPageTextValid(true)
      if (data) {
        data.page_text = pageText
        const formData: UpdateOffenderModel = {
          id: data.id,
          name: getValues('name'),
          incident_date: getValues('incident_date'),
          incident_location: getValues('incident_location'),
          incident_type: getValues('incident_type'),
          picture_url: data.picture_url,
          picture_of_offender: !data.picture_url
            ? getValues('picture_of_offender')
            : undefined,
          page_text: pageText,
        }
        const response = await saveOffender(formData, data.id)
        if (response.status === 200) {
          navigate('/admin/offenders')
        }
      } else {
        console.log(getValues())
        const formData: NewOffenderModel = {
          name: getValues('name'),
          incident_date: getValues('incident_date'),
          incident_location: getValues('incident_location'),
          incident_type: getValues('incident_type'),
          picture_of_offender: getValues('picture_of_offender'),
          page_text: pageText,
        }
        const response = await createOffender(formData)
        if (response.status === 200) {
          navigate('/admin/offenders')
        }
      }
    }
    setValidated(true)
  }

  const removePicture = () => {
    if (data) {
      data.picture_url = undefined
      forceUpdate()
    }
  }

  useEffect(() => {
    if (params.id) {
      const fetchData = async (id: number) => {
        const response = await getOffender(id)
        setData(response)
        setPageText(response.page_text)
        setValue('name', response.name)
        setValue('incident_location', response.incident_location)
        setValue('incident_type', response.incident_type)
        setValue('incident_date', response.incident_date)
      }

      fetchData(+params.id).then()
    }
  }, [params.id])

  return (
    <AdminLayout>
      <CForm
        className="row g-3 needs-validation"
        noValidate
        validated={validated}
        onSubmit={handleSubmit(onSubmit)}
      >
        <CCol md={4}>
          <CFormInput
            type="text"
            invalid={errors.name?.type === 'required'}
            feedbackInvalid="Please enter a name."
            label="Offender's name"
            {...register('name', { required: true })}
          />
        </CCol>
        <CCol md={4}>
          <Controller
            control={control}
            name={'incident_date'}
            rules={{ required: 'Please select a Incident Date' }}
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <CDatePicker
                  {...field}
                  id="incident_date"
                  date={value}
                  onDateChange={(date, formatedDate) => {
                    console.log(formatedDate)
                    onChange(formatedDate)
                  }}
                  label="Date of Incident"
                />
              )
            }}
          />
        </CCol>
        <CCol md={4}>
          <CFormInput
            type="text"
            feedbackInvalid="Please enter a location."
            id="incident_location"
            label="Location of Incident"
            invalid={errors.incident_location?.type === 'required'}
            {...register('incident_location', { required: true })}
          />
        </CCol>
        <CCol md={6}>
          <CFormLabel>Incident Type</CFormLabel>
          <CFormSelect
            {...register('incident_type', { required: true })}
            aria-label="Incident Type"
            invalid={errors.incident_type?.type === 'required'}
            options={[
              {
                label: 'Select a Incident Type',
                value: '',
              },
              { label: 'Online', value: 'online' },
              { label: 'In-Person', value: 'in-person' },
              { label: 'Online and In-Person', value: 'both' },
            ]}
            feedbackInvalid="Please select a type."
          />
        </CCol>
        <CCol md={6}>
          {data?.picture_url !== undefined && (
            <CRow>
              <CCol md={5}>
                <CFormLabel>Picture of Offender</CFormLabel>
                <img
                  src={process.env.REACT_APP_API_URL + '/' + data.picture_url}
                  alt={''}
                />
              </CCol>
              <CCol md={7}>
                <CButton type={'button'} onClick={removePicture}>
                  Delete
                </CButton>
              </CCol>
            </CRow>
          )}
          {data?.picture_url === undefined && (
            <Controller
              control={control}
              name={'picture_of_offender'}
              rules={{ required: "Offender's picture is required" }}
              render={({ field: { value, onChange, ...field } }) => {
                return (
                  <CFormInput
                    {...field}
                    id="picture_of_offender"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (!event.target.files) {
                        return
                      }
                      onChange(event.target.files[0])
                    }}
                    label="Picture of Offender"
                    feedbackInvalid="Please upload a picture."
                  />
                )
              }}
            />
          )}
        </CCol>
        <CCol md={12}>
          <CFormLabel>Enter Page Text</CFormLabel>
          <div
            className={
              !pageTextValid
                ? 'pb-5 form-control form-control-error-border'
                : 'pb-5 form-control'
            }
          >
            <QuillEditor
              initialValue={pageText}
              setTextValue={setPageText}
              height={'150px'}
            />
          </div>
          <CFormFeedback invalid={pageTextValid} className="text-danger">
            You must enter text.
          </CFormFeedback>
        </CCol>
        <CCol xs={12}>
          <CButton color="primary" type="submit">
            Submit form
          </CButton>
        </CCol>
      </CForm>
    </AdminLayout>
  )
}

export default NewOffender
