import React, { ReactNode } from 'react'
import Header from '../layout/Header'
import Footer from './Footer'
import { Box, Center } from '@chakra-ui/react'

type Props = {
  children: ReactNode
}

function DefaultLayout({ children }: Props) {
  return (
    <Center>
      <Box width={{ md: '2000px' }}>
        <Header />
        {children}
        <Footer />
      </Box>
    </Center>
  )
}

export default DefaultLayout
