import React, { ReactNode } from 'react'
import { AppAside, AppSidebar, AppFooter, AppHeader } from '../components/index'

type Props = {
  children?: ReactNode
}

const AdminLayout = ({ children }: Props) => {
  return (
    <>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <AppHeader />
        <div className="body flex-grow-1 px-3">{children}</div>
        <AppFooter />
      </div>
      <AppAside />
    </>
  )
}

export default AdminLayout
