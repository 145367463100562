import * as React from 'react'
import { AdminLayout } from '@/layout'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getSubmission } from '@/core/apis/api'
import { NewIntakeModel, UpdateOffenderModel } from '@/models/offenders.model'
import {
  CCol,
  CDatePicker,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
} from '@coreui/react-pro'

const AdminIntake = () => {
  const [data, setData] = useState<NewIntakeModel>()
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      const fetchData = async (id: number) => {
        const response = await getSubmission(id)
        setData(response)
      }

      fetchData(+params.id).then()
    }
  }, [params.id])

  return (
    <AdminLayout>
      <>
        <CCol md={6}>
          <div>Your Full Name: {data?.full_name}</div>
        </CCol>
        <CCol md={6}>
          <CFormLabel htmlFor="email">
            Your Email <span className="text-danger">*</span>
          </CFormLabel>
        </CCol>
        <CCol xs={6}>
          <div>Your Phone #: {data?.phone_number}</div>
        </CCol>
        <CCol xs={6}>
          <div>Your Location (City, State): {data?.location}</div>
        </CCol>
        <CCol md={6}>
          <CFormLabel htmlFor="incident_date">
            Date of Incident <span className="text-danger">*</span>
          </CFormLabel>
        </CCol>
        <CCol md={6}>
          <CFormLabel htmlFor="incident_type">
            Where did this incident happen?
            <span className="text-danger">*</span>
          </CFormLabel>
        </CCol>
        <CCol md={12}>
          <div>
            If this incident occurred in person, please provide approximate
            location of incident.: {data?.location}
          </div>
        </CCol>
        <CCol md={6}>
          <div>{"Offender's Name (If Available)"}</div>
        </CCol>
        <CCol md={6}>
          <div>{"Offender's Facebook Profile Link (If Available)"}</div>
        </CCol>
        <CCol md={6}>
          <div>{"Offender's Linkedin Profile Link (If Available)"}</div>
        </CCol>
        <CCol md={6}>
          <div>{"Offender's Instagram Profile Link (If Available)"}</div>
        </CCol>
        <CCol md={6}>
          <div>
            {"Offender's X(Former Twitter) Profile Link (If Available)"}
          </div>
        </CCol>
        <CCol md={6}>
          <div>{"Offender's Employer Name (If Available)"}</div>
        </CCol>
        <CCol md={12}>
          <CFormLabel htmlFor="summary">
            Summary: <span className="text-danger">*</span>
            <p className="text-muted">
              Please provide a detailed explanation of what occurred. Please
              provide any all publicly available information regarding the
              incident, the subject(s) and offender(s). The more details you can
              provide us, the more efficiently we will be able to address the
              incident.
            </p>
          </CFormLabel>
        </CCol>
      </>
    </AdminLayout>
  )
}

export default AdminIntake
