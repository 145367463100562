import React, { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultLayout } from '../../../layout'
import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
} from '@coreui/react-pro'
import { NewIntakeModel } from '@/models/offenders.model'
import { saveIntake } from '@/core/apis/api'

function initialFormValues(): NewIntakeModel {
  return {
    email: '',
    full_name: '',
    incident_date: '',
    incident_location: '',
    incident_type: '',
    location: '',
    offender_employer: '',
    offender_facebook: '',
    offender_instagram: '',
    offender_linkedin: '',
    offender_name: '',
    offender_twitter: '',
    phone_number: '',
    summary: '',
    attachments: undefined,
  }
}
const Intake = () => {
  const [values, setValues] = useState(initialFormValues)
  const navigate = useNavigate()

  function handleFileUpload(files: FileList) {
    setValues({
      ...values,
      attachments: files,
    })
  }
  function handleDateChange(
    date: Date | null,
    formatedDate: string | undefined,
  ) {
    setValues({
      ...values,
      incident_date: formatedDate,
    })
  }
  function handleChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) {
    const { name, value } = event.target

    setValues({
      ...values,
      [name]: value,
    })
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    try {
      const response = await saveIntake(values)
      if (response.status === 200) {
        navigate('/thank-you')
      }
    } catch (error) {
      /**
       * an error handler can be added here
       */
    }
  }

  return (
    <DefaultLayout>
      <h4 className="p-5 text-center">
        IF YOU HAVE WITNESSED ANTI ISLAMOPHOBIA, PLEASE LET STOPMUSLIMHATE BY
        SUBMITTING THE FORM BELOW.
        <h5 className="pt-3">IF THIS IS AN EMERGENCY, PLEASE CALL 911.</h5>
        <h5 className="pt-3">
          DISCLAIMER: WHEN SUBMITTING AN INCIDENT, YOU GIVE PERMISSION TO
          STOPMUSLIMHATE TO PUBLISH THE CONTENT TO OUR PLATFORMS AND MEDIA
          PARTNERS AS NEEDED
        </h5>
      </h4>

      <CForm
        className="row g-3 m-auto"
        style={{ maxWidth: '1000px' }}
        onSubmit={handleSubmit}
      >
        <CCol md={6}>
          <CFormLabel htmlFor="full_name">
            Your Full Name <span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="text"
            id="full_name"
            name="full_name"
            value={values.full_name}
            onChange={handleChange}
            required
          />
        </CCol>
        <CCol md={6}>
          <CFormLabel htmlFor="email">
            Your Email <span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="email"
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            required
          />
        </CCol>
        <CCol xs={6}>
          <CFormInput
            id="phone_number"
            name="phone_number"
            label="Your Phone #"
            value={values.phone_number}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs={6}>
          <CFormInput
            id="location"
            name={'location'}
            label="Your Location (City, State)"
            value={values.location}
            onChange={handleChange}
          />
        </CCol>
        <CCol md={6}>
          <CFormLabel htmlFor="incident_date">
            Date of Incident <span className="text-danger">*</span>
          </CFormLabel>
          <CDatePicker
            inputReadOnly
            footer
            locale="en-US"
            id="incident_date"
            date={values.incident_date}
            maxDate={new Date()}
            onDateChange={handleDateChange}
          />
        </CCol>
        <CCol md={6}>
          <CFormLabel htmlFor="incident_type">
            Where did this incident happen?
            <span className="text-danger">*</span>
          </CFormLabel>
          <CFormSelect
            id="incident_type"
            name={'incident_type'}
            value={values.incident_type}
            onChange={handleChange}
            options={[
              {
                label: 'Select a Incident Type',
                value: '',
              },
              { label: 'Online', value: 'online' },
              { label: 'In-Person', value: 'in-person' },
              { label: 'Online and In-Person', value: 'both' },
            ]}
          />
        </CCol>
        <CCol md={12}>
          <CFormInput
            id="incident_location"
            name="incident_location"
            value={values.incident_location}
            onChange={handleChange}
            label="If this incident occurred in person, please provide approximate location of incident."
          />
        </CCol>
        <CCol md={6}>
          <CFormInput
            id="offender_name"
            name="offender_name"
            value={values.offender_name}
            onChange={handleChange}
            label="Offender's Name (If Available)"
          />
        </CCol>
        <CCol md={6}>
          <CFormInput
            id="offender_facebook"
            name="offender_facebook"
            value={values.offender_facebook}
            onChange={handleChange}
            label="Offender's Facebook Profile Link (If Available)"
          />
        </CCol>
        <CCol md={6}>
          <CFormInput
            id="offender_linkedin"
            name="offender_linkedin"
            value={values.offender_linkedin}
            onChange={handleChange}
            label="Offender's Linkedin Profile Link (If Available)"
          />
        </CCol>
        <CCol md={6}>
          <CFormInput
            id="offender_instagram"
            name="offender_instagram"
            value={values.offender_instagram}
            onChange={handleChange}
            label="Offender's Instagram Profile Link (If Available)"
          />
        </CCol>
        <CCol md={6}>
          <CFormInput
            id="offender_twitter"
            name="offender_twitter"
            value={values.offender_twitter}
            onChange={handleChange}
            label="Offender's X(Former Twitter) Profile Link (If Available)"
          />
        </CCol>
        <CCol md={6}>
          <CFormInput
            id="offender_employer"
            name="offender_employer"
            value={values.offender_employer}
            onChange={handleChange}
            label="Offender's Employer Name (If Available)"
          />
        </CCol>
        <CCol md={12}>
          <CFormLabel htmlFor="summary">
            Summary: <span className="text-danger">*</span>
            <p className="text-muted">
              Please provide a detailed explanation of what occurred. Please
              provide any all publicly available information regarding the
              incident, the subject(s) and offender(s). The more details you can
              provide us, the more efficiently we will be able to address the
              incident.
            </p>
          </CFormLabel>
          <CFormTextarea
            id="summary"
            name="summary"
            value={values.summary}
            onChange={handleChange}
            required
            rows={5}
          />
        </CCol>
        <CCol xs={12}>
          <CFormInput
            type="file"
            id="attachments"
            name="attachments"
            onChange={(event) => {
              if (!event.target.files) {
                return
              }
              handleFileUpload(event.target.files)
            }}
            multiple
            label="Please attach any screenshots or relevant documenation"
          />
        </CCol>
        <CCol xs={12}>
          <CButton type="submit">Submit</CButton>
        </CCol>
      </CForm>
    </DefaultLayout>
  )
}

export default Intake
