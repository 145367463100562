import React from 'react'
import Hero from '../../../components/Hero'
import { OffendersCarousel } from '../../../components'
import { DefaultLayout } from '../../../layout'

const Home = () => {
  return (
    <>
      <DefaultLayout>
        <Hero />
        <OffendersCarousel />
      </DefaultLayout>
    </>
  )
}

export default Home
