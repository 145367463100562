import * as React from 'react'
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { Button } from '@mui/material'
import { useRoutePaths } from '@/hooks'
import { useNavigate } from 'react-router-dom'

function Footer() {
  const navigate = useNavigate()
  const { INTAKE_PATH } = useRoutePaths()

  const navigateToIntake = () => {
    navigate(INTAKE_PATH)
  }

  return (
    <Box>
      <Flex
        bg={'#E9F2EE'}
        direction={{ base: 'column', md: 'row' }}
        align="center"
        padding={{ sm: '30px 10px' }}
        px={{ base: '2' }}
      >
        <div>
          <Flex paddingBottom="15px">
            <Text
              fontSize="2xl"
              fontFamily={'heading'}
              fontWeight={'bold'}
              color={useColorModeValue('blackAlpha.900', 'blackAlpha.900')}
            >
              Muslim hate stops here!
            </Text>
          </Flex>
          <Text paddingBottom="10px" fontWeight={'semibold'}>
            Have you been discriminated against, lost your employment,
            threatened physically or verbally?
          </Text>
          <Text fontWeight={'semibold'}>
            Give us information about the perpetrators, we will investigate then
            publicize them on your behalf.
          </Text>
        </div>
        {/*<div className='h-100'>*/}
        <Flex width={'50%'} justifyContent={'center'}>
          <Box>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigateToIntake()}
            >
              Tell us your story
            </Button>
          </Box>
        </Flex>
        {/*</div>*/}
      </Flex>
    </Box>
  )
}

export default Footer
