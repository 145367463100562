import React, { FormEvent, useEffect, useState } from 'react'
import { useSession } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'

function initialFormValues() {
  return {
    email: '',
    password: '',
  }
}

const Login = () => {
  const [values, setValues] = useState(initialFormValues)
  const [loginRequestStatus, setLoginRequestStatus] = useState('success')
  const { signIn } = useSession()
  const navigate = useNavigate()

  // function handleUserChange(event: React.ChangeEvent<HTMLSelectElement>) {
  //   const user = event.target.value
  //   setValues(JSON.parse(user))
  // }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target

    setValues({
      ...values,
      [name]: value,
    })
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    setLoginRequestStatus('loading')

    try {
      await signIn(values)
    } catch (error) {
      /**
       * an error handler can be added here
       */
    } finally {
      setLoginRequestStatus('success')
      navigate('/admin')
    }
  }

  useEffect(() => {
    // clean the function to prevent memory leak
    setValues({ email: 'admin@admin.com', password: 'Test1234' })
    return () => setLoginRequestStatus('success')
  }, [])

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCard className="p-4">
              <CCardBody>
                <CForm onSubmit={handleSubmit}>
                  <h1>Login</h1>
                  <p className="text-medium-emphasis">
                    Sign In to your account
                  </p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      value={values.email}
                      type={'email'}
                      name={'email'}
                      id={'email'}
                      placeholder="email"
                      autoComplete={'email'}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      value={values.password}
                      name={'password'}
                      id={'password'}
                      disabled={loginRequestStatus === 'loading'}
                      onChange={handleChange}
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol xs={6}>
                      <CButton
                        color="primary"
                        className="px-4"
                        type={'submit'}
                        disabled={loginRequestStatus === 'loading'}
                      >
                        {loginRequestStatus === 'loading'
                          ? 'Loading...'
                          : 'Submit'}
                      </CButton>
                    </CCol>
                    <CCol xs={6} className="text-right">
                      <CButton color="link" className="px-0">
                        Forgot password?
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
