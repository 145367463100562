import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useRoutePaths } from '../../hooks'
import {
  Home,
  Login,
  Offenders,
  Page404,
  Offender,
  Blank,
  Dashboard,
  AdminOffenders,
  NewOffender,
} from '../../views'
import { PublicRoute } from '../PublicRoute'
import { PrivateRoute } from '../PrivateRoute'
import { Intake } from '@/views/pages/intake'
import { ThankYou } from '@/views/pages/intake'
import { AdminIntake } from '@/views/admin/intake'

function Router() {
  const {
    ROOT_PATH,
    LOGIN_PATH,
    INTAKE_PATH,
    THANK_YOU_PATH,
    PUBLIC_OFFENDERS_PATH,
    PUBLIC_OFFENDER_PATH,
    ADMIN_PATH,
    DASHBOARD_PATH,
    BLANK_PATH,
    ADMIN_OFFENDERS_PATH,
    ADMIN_OFFENDER_NEW_PATH,
    ADMIN_OFFENDER_ID_PATH,
    ADMIN_INTAKE_ID_PATH,
  } = useRoutePaths()

  return (
    <Routes>
      <Route path={ROOT_PATH} element={<Home />} />
      <Route path={PUBLIC_OFFENDER_PATH} element={<Offender />} />
      <Route path={PUBLIC_OFFENDERS_PATH} element={<Offenders />} />
      <Route path={INTAKE_PATH} element={<Intake />} />
      <Route path={THANK_YOU_PATH} element={<ThankYou />} />

      <Route
        path={LOGIN_PATH}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path={DASHBOARD_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path={BLANK_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <Blank />
          </PrivateRoute>
        }
      />

      <Route
        path={ADMIN_OFFENDER_NEW_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <NewOffender />
          </PrivateRoute>
        }
      />

      <Route
        path={ADMIN_OFFENDER_ID_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <NewOffender />
          </PrivateRoute>
        }
      />

      <Route
        path={ADMIN_INTAKE_ID_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <AdminIntake />
          </PrivateRoute>
        }
      />

      <Route
        path={ADMIN_OFFENDERS_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <AdminOffenders />
          </PrivateRoute>
        }
      />

      <Route
        path={ADMIN_PATH}
        element={<Navigate to="/admin/dashboard" replace />}
      />

      {/*<Route path={REGISTER_PATH} element={<Register />} />*/}

      {/*<Route*/}
      {/*  path={METRICS_PATH}*/}
      {/*  element={*/}
      {/*    <PrivateRoute permissions={['metrics.list']} redirectTo={LOGIN_PATH}>*/}
      {/*      <Metrics />*/}
      {/*    </PrivateRoute>*/}
      {/*  }*/}
      {/*/>*/}

      {/*<Route*/}
      {/*  path={USERS_PATH}*/}
      {/*  element={*/}
      {/*    <PrivateRoute permissions={['users.list', 'users.create']}>*/}
      {/*      <Users />*/}
      {/*    </PrivateRoute>*/}
      {/*  }*/}
      {/*/>*/}

      {/*<Route*/}
      {/*  path={USER_PATH}*/}
      {/*  element={*/}
      {/*    <PrivateRoute permissions={['users.list', 'users.create']}>*/}
      {/*      <Users />*/}
      {/*    </PrivateRoute>*/}
      {/*  }*/}
      {/*/>*/}

      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

export default Router
